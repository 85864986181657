/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { type Session } from "next-auth";

class CrazyEggClass {
  identify = (user: Session["user"]) => {
    if (window.CE2 && window.CE2.identify) {
      window.CE2.identify(user.email);
    } else {
      // if not loaded yet, set the recording identifier once the script loads
      // @ts-ignore
      window.CE_READY = function () {
        window.CE2.identify(user.email);
      };
    }
  };

  designSurvey = () => {
    if (window.CE2) {
      setTimeout(() => {
        console.log("Crazy Egg Survey Init");
        window.CE2.showSurvey("26be516a-6338-4ed3-9abd-0767a188e67c");
      }, 50000);
    }
  };
}

const CrazyEgg = new CrazyEggClass();

export default CrazyEgg;
