function safeDecode(str: string) {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    return str;
  }
}

const parseQuery = (queryString?: string): { [key: string]: string } => {
  const query = {};
  ((queryString || window.location.search || "").split("?")[1] || "").replace(
    /([^?&=]+)=([^&]*)/g,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    (_, k, v) => (query[k] = safeDecode(v))
  );
  return query;
};

function getUTMQueries() {
  const query = parseQuery();
  const campaignKeywords = "utm_source utm_medium utm_campaign utm_content utm_term".split(" ");
  const utmQuery = campaignKeywords.reduce((a, c) => {
    // @ts-ignore
    if (query[c]) a[c] = query[c];
    return a;
  }, {});
  return utmQuery;
}

export { getUTMQueries, parseQuery };
