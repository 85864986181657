/* eslint-disable @next/next/no-img-element */
import {
  useState,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
  Fragment,
  useEffect,
} from "react";
import { signIn, signOut } from "next-auth/react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  IoCloseSharp,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoPinterest,
  IoLogoTiktok,
  IoMenuOutline,
} from "react-icons/io5";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import constants from "app/constants/client";
import ImgWhiteLogo from "app/assets/icons/roomable-logo-white.png";
import ImgIcon from "app/assets/icons/roomable-icon.png";
import Button from "../base/Button";
import useContextUser from "app/context/useContextUser";
import { HiSparkles } from "react-icons/hi2";
import { type User } from "@prisma/client";
import { HiChevronDown, HiOutlineUserCircle } from "react-icons/hi";
import { cn } from "app/lib/utils";
import { TbHistory, TbLogout } from "react-icons/tb";
import UserAvatar from "../misc/UserAvatar";
import Analytics from "app/utils/analytics-client";

const loggedOutNavigation = [
  { name: "How It Works", href: "/#how-it-works" },
  { name: "Why Roomable", href: "/#why-roomable" },
  { name: "Gallery", href: "/discover" },
  { name: "For Professionals", href: "/professionals" },
];

const navigation = [
  { name: "Gallery", href: "/discover" },
  { name: "Design", href: "/design" },
  { name: "Find a Pro", href: "/interior-designers" },
];

const authNavigation = [
  { name: "History", href: "/design/history" },
  { name: "My Moodboards", href: "/moodboard" },
  { name: "My Account", href: "/account" },
];

const mobileWebScrollNavigation = [
  { name: "Gallery", href: "/discover" },
  { name: "Design", href: "/design", hasIcon: true },
  { name: "Find a Pro", href: "/interior-designers" },
  { name: "For Professionals", href: "/professionals" },
];

const AIIcon = () => (
  <div className="ml-1.5 flex items-center justify-center rounded-md border-[1px] border-brand-red/70 bg-brand-red bg-opacity-10 px-1.5 text-brand-red/80">
    <span className="mr-1">AI</span>
    <span className="">
      <HiSparkles size={16} className="mt-0" />
    </span>
  </div>
);

const NavMenu: React.FC<{ user: User | null }> = ({ user }) => {
  if (!user?.id) return null;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full items-center justify-center rounded-md px-2 py-2 text-base  font-medium text-zinc-300 hover:bg-white/5 hover:text-white focus:outline-none focus-visible:ring-2">
          <div className="mr-2">
            <UserAvatar user={user} />
          </div>
          {user.firstname || user.name?.split(" ")[0] || "Account"}
          <HiChevronDown className="mt-.5 -mr-1 ml-2 h-6 w-6" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 flex w-60 origin-top-right flex-col rounded-md bg-brand-dark-2 p-4 font-normal shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            <Link href="/professionals" className="mb-2 w-full text-center">
              <Button variant="secondary" className="w-full">
                Join As Pro
              </Button>
            </Link>
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <Link
                href="/design/history"
                className={cn([active && `bg-white/5`, `mt-0.5 rounded-md`])}
              >
                <div className="flex items-center px-4 py-2 text-zinc-100">
                  <span>
                    <TbHistory size={18} />
                  </span>
                  <span className="ml-1.5">History</span>
                </div>
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link href="/account" className={cn([active && `bg-white/5`, `mt-0.5 rounded-md`])}>
                <div className="flex items-center px-4 py-2 text-zinc-100">
                  <span>
                    <HiOutlineUserCircle size={18} />
                  </span>
                  <span className="ml-1.5">My Account</span>
                </div>
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => void signOut()}
                type="button"
                className={cn([active && `bg-white/5`, `mt-0.5 rounded-md`])}
              >
                <div className="flex items-center px-4 py-2 text-zinc-100">
                  <span>
                    <TbLogout size={18} />
                  </span>
                  <span className="ml-1.5">Log Out</span>
                </div>
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [displayTransparentHeader, setDisplayTransparentHeader] = useState<boolean>(true);

  const router = useRouter();
  const { user, isAuthenticated, isAuthReady } = useContextUser();
  const nav = isAuthenticated ? navigation : loggedOutNavigation;
  const mobileScrollNav = isAuthenticated ? mobileWebScrollNavigation : loggedOutNavigation;
  // could be in _app, here because of session

  useEffect(() => {
    if (window.location.pathname === "/") {
      const checkScroll = () => {
        setDisplayTransparentHeader(window.scrollY === 0);
      };
      window.addEventListener("scroll", checkScroll);
      return () => window.removeEventListener("scroll", () => setDisplayTransparentHeader(false));
    } else {
      setDisplayTransparentHeader(false);
    }
  }, []);

  return (
    <>
      <div className={`isolate mt-36 bg-black md:mt-28`}>
        <div className="isolate bg-brand-dark">
          <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl md:top-[-20rem]">
            <svg
              className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] md:left-[calc(50%-30rem)] md:h-[42.375rem]"
              viewBox="0 0 1155 678"
            >
              <path
                fill="url(#looking-for-answers)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="looking-for-answers"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor={"#1d1b26"} />
                  <stop offset={1} stopColor="#f73859" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div
            className={cn(
              "fixed top-0 z-50 w-full",
              displayTransparentHeader ? "" : "backdrop-blur-[50px]"
            )}
          >
            <nav
              className="flex items-center justify-between border-b border-solid border-white/10 px-4 py-4 md:px-10"
              aria-label="Global"
            >
              <div className="flex">
                <Link href="/homeowners" className="-m-1.5 flex px-1.5">
                  <span className="sr-only">Roomable.ai</span>
                  <div className="py-1.5">
                    <Image
                      src={ImgWhiteLogo}
                      alt=""
                      width={139}
                      height={43}
                      priority
                      placeholder="blur"
                    />
                  </div>
                </Link>
                <div className="ml-[24px] hidden items-center gap-x-6 lg:flex">
                  {nav.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      className={`flex flex-row place-items-start items-center text-base font-semibold leading-6 text-white hover:text-brand-red`}
                      onClick={() => {
                        Analytics.homepageNavBarLink({ navbar_component: item.name });
                      }}
                    >
                      {item.name}
                      {item.name === "Design" && <AIIcon />}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex lg:hidden">
                {/* {(subscriptionStatus?.currentPlan === "free" ||
                  subscriptionStatus?.currentPlan === "individual") && (
                  <Link
                    href="/pricing"
                    className={`mr-4 flex h-8 items-center rounded-3xl bg-white px-4 py-1.5 text-center text-sm font-semibold text-brand-red md:h-9`}
                  >
                    {subscriptionStatus?.currentBalance}{" "}
                    {(subscriptionStatus?.currentBalance || 0) > 1 ? "designs" : "design"} left
                  </Link>
                )} */}

                {!isAuthenticated && (
                  <Button
                    onClick={() => {
                      void router.push("/auth/signin?callbackUrl=%2Fdashboard&mode=signin");
                      setMobileMenuOpen(false);
                    }}
                    variant="red"
                    className="mr-[14px] w-[auto] whitespace-nowrap px-3 text-sm font-normal text-white"
                  >
                    Design Your Home
                  </Button>
                )}

                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-zinc-50"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <IoMenuOutline className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="hidden  py-1 text-base font-semibold leading-6 text-brand-red lg:flex lg:flex-1 lg:justify-end">
                {/* {(subscriptionStatus?.currentPlan === "free" ||
                  subscriptionStatus?.currentPlan === "individual") && (
                  <Link
                    href="/pricing"
                    className={`upgrade-btn mr-4 h-8 min-w-[125px] rounded-3xl bg-white px-3 py-1.5 text-center text-base text-brand-red md:h-9`}
                    onMouseEnter={() => {
                      setIsUpgradeButtonHovered(true);
                    }}
                    onMouseLeave={() => {
                      setIsUpgradeButtonHovered(false);
                    }}
                  >
                    {!isUpgradeButtonHovered && (
                      <>
                        {subscriptionStatus?.currentBalance}{" "}
                        {(subscriptionStatus?.currentBalance || 0) > 1 ? "designs" : "design"} left
                      </>
                    )}
                    <span>Upgrade</span>
                  </Link>
                )} */}
                {/* {!isAuthenticated && (
                  <div className="mx-6 hidden items-center gap-x-6 lg:flex">
                    <Link
                      href="/professionals"
                      className={`${
                        router.pathname === "/professionals" ? "text-zinc-50" : "text-zinc-300"
                      } flex flex-row place-items-start text-base font-semibold leading-6 text-zinc-300 hover:text-brand-red`}
                    >
                      For Professionals
                    </Link>
                  </div>
                )} */}
                <AuthCTA
                  isAuthenticated={isAuthenticated}
                  isAuthReady={isAuthReady}
                  setMobileMenuOpen={setMobileMenuOpen}
                />
                {isAuthenticated && (
                  <div className="mx-6 hidden items-center gap-x-6 lg:flex">
                    <Link
                      href={"/moodboard"}
                      className={`${
                        router.pathname.includes("/moodboard") ? "text-zinc-50" : "text-zinc-300"
                      } flex flex-row place-items-start text-base font-semibold leading-6 hover:text-brand-red`}
                    >
                      My Moodboards
                    </Link>
                  </div>
                )}
                <NavMenu user={user} />
              </div>
            </nav>

            <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-brand-dark px-6 py-6 lg:hidden">
                <div className="flex items-center justify-between">
                  <Link href="/homeowners" className="-m-1.5 p-1.5">
                    <span className="sr-only">Roomable</span>
                    <Image src={ImgIcon} alt="" width={44} height={44} />
                  </Link>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-zinc-100"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <IoCloseSharp className="h-8 w-8" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-brand-red/10">
                    <div className="space-y-2 py-6 font-normal">
                      {user?.id && (
                        <div className="flex items-center justify-between">
                          <div className="flex max-w-[60%] items-center overflow-hidden rounded-md py-2 text-sm">
                            <div className="mr-2">
                              <UserAvatar user={user} />
                            </div>
                            <div>
                              <div className="overflow-hidden text-ellipsis font-medium text-zinc-100">
                                {user.firstname || user.name?.split(" ")[0] || "Account"}
                              </div>
                              <div className="mt-1 overflow-hidden text-ellipsis text-xs font-normal text-zinc-300">
                                {user.email}
                              </div>
                            </div>
                          </div>
                          <div>
                            <Link href="/professionals" className="mb-2 w-full text-center">
                              <Button
                                variant="secondary"
                                className="w-full px-3 font-normal"
                                size="sm"
                              >
                                Join As Pro
                              </Button>
                            </Link>
                          </div>
                        </div>
                      )}

                      {nav.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={cn(
                            "-mx-3 flex flex-row place-items-start items-center rounded-lg px-3 py-2 text-lg leading-7  text-white hover:text-brand-red hover:text-brand-red/80"
                          )}
                          onClick={() => {
                            Analytics.homepageNavBarLink({ navbar_component: item.name });
                          }}
                        >
                          {item.name}
                          {item.name === "Design" && <AIIcon />}
                        </a>
                      ))}
                      {/*
                      {!isAuthenticated &&
                        [{ name: "For Professionals", href: "/professionals" }].map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className=" -mx-3 flex flex-row place-items-start rounded-lg px-3 py-2 text-lg leading-7 text-zinc-200 hover:text-brand-red hover:text-brand-red/80"
                          >
                            {item.name}
                          </a>
                        ))} */}

                      {isAuthenticated && (
                        <>
                          {authNavigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-mx-3 flex flex-row place-items-start rounded-lg px-3 py-2 text-lg leading-7 text-zinc-200 hover:text-brand-red hover:text-brand-red/80"
                            >
                              {item.name}
                            </a>
                          ))}
                        </>
                      )}
                    </div>

                    <div className="-mx-3 block py-6 text-base font-semibold leading-6 text-brand-red hover:text-brand-red/80">
                      <div className="mx-3">
                        <AuthCTA
                          isAuthenticated={isAuthenticated}
                          isAuthReady={isAuthReady}
                          setMobileMenuOpen={setMobileMenuOpen}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>

            <div className="flex flex-row justify-start gap-6 overflow-x-auto overscroll-none scroll-smooth whitespace-nowrap px-4 py-1 md:hidden">
              {mobileScrollNav.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className={cn(
                    `-mx-3 flex flex-row place-items-start items-center rounded-lg px-3 py-2 text-sm font-semibold leading-5 text-white  hover:text-brand-red hover:text-brand-red/80`
                  )}
                  onClick={() => {
                    Analytics.homepageBarLink({ homepage_bar_icon: item.name });
                  }}
                >
                  {item.name}
                  {item.name === "Design" && <AIIcon />}
                </Link>
              ))}
            </div>
          </div>

          <main className="min-h-screen">{children}</main>

          <div className="container mx-auto mt-8 flex flex-col items-start space-y-10 px-4 text-left md:flex-row md:space-y-0">
            <div className="flex-1">
              <Link href="/homeowners" className="mb-8 flex ">
                <div className="py-1.5">
                  <Image
                    src={ImgWhiteLogo}
                    alt=""
                    width={139}
                    height={43}
                    priority
                    placeholder="blur"
                  />
                </div>
              </Link>
              <div className="flex flex-row">
                <p className="text-base font-normal leading-6">How can we help? </p>
                <div>
                  <Link
                    href={"mailto:support@roomable.ai"}
                    className="ml-0.5 text-base font-normal leading-6 text-brand-red "
                  >
                    Contact us
                  </Link>
                </div>
              </div>
              <div className="mt-4 flex space-x-4 text-3xl">
                <a href="https://www.facebook.com/roomableai">
                  <IoLogoFacebook />
                </a>
                <a href="https://www.instagram.com/roomableai/">
                  <IoLogoInstagram />
                </a>
                <a href="https://www.linkedin.com/company/roomableai">
                  <IoLogoLinkedin />
                </a>
                <a href="https://www.pinterest.com/roomableai/">
                  <IoLogoPinterest />
                </a>
                <a href="https://www.tiktok.com/@roomableai">
                  <IoLogoTiktok />
                </a>
                <a href="https://twitter.com/roomableai">
                  <img
                    src={`${constants.CloudFront}/assets/X_logo.png`}
                    alt=""
                    height={29}
                    className="h-[29px]"
                  />
                </a>
              </div>

              <div>
                <a href="https://www.iterative.vc/companies" className="cursor-pointer">
                  <p className="mt-8 text-base font-normal leading-6">Proudly backed by </p>
                  <img
                    src={`${constants.CloudFront}/assets/iterative_logo.png`}
                    alt=""
                    height={77}
                    className="h-[77px]"
                  />
                </a>
                <p className="mt-8 hidden text-base font-normal leading-6 md:block">
                  © 2023 Roomable. All rights reserved.
                </p>
              </div>
            </div>
            <div className="flex flex-1 flex-col space-y-10 md:flex-row md:space-y-0">
              <div className="w-full flex-1">
                <div className="mb-6 text-base font-bold">Features</div>
                <div className="space-y-4 font-normal">
                  {/* <div>
                    <Link href="/homeowners">Home</Link>
                  </div> */}
                  {/* <div>
                    <Link href="/pricing">Pricing</Link>
                  </div> */}
                  <div>
                    <Link href="/design">Design Your Room</Link>
                  </div>
                  <div>
                    <Link href="/discover">Gallery</Link>
                  </div>
                  <div>
                    <Link href="/interior-designers">Find a Pro</Link>
                  </div>
                  <div>
                    <Link href="/professionals">For Professionals</Link>
                  </div>
                  <div>
                    {!isAuthenticated && (
                      <button onClick={() => void signIn()}>Sign in to Roomable</button>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex-1">
                <div className="mb-6 text-base font-bold">Company</div>
                <div className="space-y-4 font-normal">
                  <div>
                    {" "}
                    <Link href="https://www.linkedin.com/company/roomableai">About Us</Link>
                  </div>
                  <div>
                    {" "}
                    <Link href={"mailto:support@roomable.ai"}>Contact</Link>
                  </div>
                </div>
              </div>

              <div className="w-full flex-1">
                <div className="mb-6 text-base font-bold">Legal</div>
                <div className="space-y-4 font-normal">
                  <div>
                    <Link href="/privacy">Privacy Policy</Link>
                  </div>
                  <div>
                    <Link href="/terms">Terms & Conditions</Link>
                  </div>
                  {/* <div>
                    <Link href="/account">My Account</Link>
                  </div>
                  <div>
                    <Link href="/design/history">History</Link>
                  </div> */}
                  {/* {!isAuthenticated && <button onClick={() => void signIn()}>Sign Up</button>} */}
                </div>
                <p className="mt-8 block text-base font-normal leading-6 md:hidden">
                  © 2023 Roomable. All rights reserved.
                </p>
              </div>
            </div>
          </div>
          <div className="container mx-auto my-12 border-b-2 border-b-zinc-50/10 md:my-28"></div>
        </div>
      </div>
    </>
  );
};

export default Layout;

const AuthCTA: React.FC<{
  isAuthenticated: boolean;
  isAuthReady: boolean;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isAuthenticated, setMobileMenuOpen, isAuthReady }) => {
  const {
    push,
    pathname,
    query: { callbackUrl, ...query },
  } = useRouter();
  const callbackQuery = Object.keys(query).length
    ? `?${Object.keys(query)
        .map((key) => `${String(key)}=${String(query[key])}`)
        .join("&")}`
    : "";

  if (!isAuthReady) return null;

  return isAuthenticated ? (
    <div className="block md:hidden">
      <Button
        onClick={() => void signOut()}
        variant="secondary"
        size="xs"
        className="h-9 w-full whitespace-nowrap px-4 text-base font-normal text-white"
      >
        Log Out
      </Button>
    </div>
  ) : (
    <div className="flex flex-col-reverse gap-y-4 md:flex-row">
      <Button
        onClick={() => {
          void push(
            `/auth/signin?callbackUrl=${encodeURIComponent(
              (callbackUrl as string) || `${pathname}${callbackQuery}`
            )}&mode=signin`
          );
          setMobileMenuOpen(false);
        }}
        variant="secondary"
        className="mr-4 w-full whitespace-nowrap px-4 text-base font-normal text-white"
      >
        Log in
      </Button>
      <Button
        onClick={() => {
          void push("/auth/signin?callbackUrl=%2Fdashboard&mode=signin");
          setMobileMenuOpen(false);
        }}
        variant="red"
        className="w-full whitespace-nowrap px-4 text-base font-normal text-white"
      >
        Design Your Home
      </Button>
    </div>
  );
};
