/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { env } from "app/env.mjs";

class GoogleAnalyticsClass {
  pageView = (url: string) => {
    if (window && window.gtag)
      // @ts-ignore
      window.gtag("config", env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
        page_path: url,
      });
  };
  event = ({ action, category, label, value }: any) => {
    if (window && window.gtag)
      // @ts-ignore
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value,
      });
  };
}

const GoogleAnalytics = new GoogleAnalyticsClass();

export default GoogleAnalytics;
