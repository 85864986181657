import { type User } from "@prisma/client";
import Avatar from "boring-avatars";
import Image from "next/image";
import React from "react";

const UserAvatar: React.FC<{ user: User }> = ({ user }) => {
  if (user.image)
    return (
      <Image
        className="rounded-full"
        src={user.image}
        width={32}
        height={32}
        unoptimized
        alt="hidden"
      ></Image>
    );

  return (
    <Avatar
      size={32}
      name={user.id}
      variant="marble"
      colors={["#CC2649", "#992C4B", "#66324C", "#33384", "E#003E4F"]}
    />
  );
};

export default UserAvatar;
