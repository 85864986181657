import { signIn } from "next-auth/react";
import { useContext, useEffect } from "react";
import Context from ".";

function useContextUser({ isAuthRequired }: { isAuthRequired?: boolean } = {}) {
  const { user } = useContext(Context);

  useEffect(() => {
    if (isAuthRequired && user.isAuthReady && !user.isAuthenticated) {
      void signIn();
    }
  }, [isAuthRequired, user.isAuthReady, user.isAuthenticated]);

  return user;
}

export default useContextUser;
