import { type Session } from "next-auth";
import GoogleAnalytics from "./google-analytics";
import Mixpanel from "./mixpanel-analytics";
import CrazyEgg from "./crazy-egg-analytics";

type Properties = { [key: string]: any };

class AnalyticsManager {
  pageView = (url: string, title: string) => {
    console.log("AnalyticsManager:pageView:", url, title);
    GoogleAnalytics.pageView(url);
    Mixpanel.pageView(url, title);
  };

  identify = (user: Session["user"]) => {
    if (!user?.id) return;
    console.log("AnalyticsManager:identify", user.id);
    Mixpanel.identify(user);
    CrazyEgg.identify(user);
  };

  signUp = (properties: Properties) => {
    Mixpanel.signUp(properties);
  };

  designRequested = (properties: Properties) => {
    Mixpanel.designRequested(properties);
  };

  designGenerated = (properties: Properties) => {
    Mixpanel.designGenerated(properties);
    CrazyEgg.designSurvey();
  };

  shopping = (properties: Properties) => {
    Mixpanel.shopping(properties);
  };

  imageDownloaded = (properties: Properties) => {
    Mixpanel.imageDownloaded(properties);
  };

  imageZoom = (properties: Properties) => {
    Mixpanel.imageZoom(properties);
  };

  imageFeedback = (properties: Properties) => {
    Mixpanel.imageFeedback(properties);
  };

  share = (properties: Properties) => {
    Mixpanel.share(properties);
  };

  pricingModalPopup = (properties: Properties) => {
    Mixpanel.pricingModalPopup(properties);
  };

  comingSoonPopup = (properties: Properties) => {
    Mixpanel.comingSoonPopup(properties);
  };

  click = (item: string, properties?: Properties) => {
    Mixpanel.click(item, properties);
  };

  joinWaitlist = (properties?: Properties) => {
    Mixpanel.joinWaitList(properties);
  };

  moodboard = (event_name: string, properties?: Properties) => {
    Mixpanel.moodboard(event_name, properties);
  };

  findAProFormStart = (properties?: Properties) => {
    Mixpanel.findAProFormStart(properties);
  };

  findAProFormEnd = (properties?: Properties) => {
    Mixpanel.findAProFormEnd(properties);
  };

  discoverPageFilter = (filter_name: "designStyle" | "roomType", properties?: Properties) => {
    if (filter_name === "designStyle") {
      Mixpanel.discoverFilter("Discovery Feed Design Style Filter", properties);
      return;
    }
    Mixpanel.discoverFilter("Discovery Feed Room Type Filter", properties);
  };

  homepageBarLink = (properties?: Properties) => {
    Mixpanel.homepageBarLink(properties);
  };

  homepageNavBarLink = (properties?: Properties) => {
    Mixpanel.homepageNavBarLink(properties);
  };

  proLandingNavBarLink = (properties?: Properties) => {
    Mixpanel.proLandingNavBarLink(properties);
  };

  dashboardNavBarLink = (properties?: Properties) => {
    Mixpanel.dashboardNavBarLink(properties);
  };

  dashboardEditProject = (properties?: Properties) => {
    Mixpanel.dashboardEditProject(properties);
  };

  homepageCtaLink = (properties?: Properties) => {
    Mixpanel.homepageCtaLink(properties);
  };

  onboarding = (title: "Start" | "Completed" | "Skip", properties: Properties = {}) => {
    Mixpanel.track(`Onboarding: ${title}`, properties);
  };
}

const Analytics = new AnalyticsManager();

export default Analytics;
