import * as React from "react";
import { type VariantProps, cva } from "class-variance-authority";

import { cn } from "app/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-sm text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-pink/80 disabled:opacity-60 disabled:pointer-events-none px-2",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        red: "bg-brand-pink text-white hover:bg-brand-pink/90",
        "red-outline":
          "bg-transparent text-zinc-300 hover:bg-brand-pink/5 border-2 border-brand-pink",
        secondary: "bg-neutral-100 bg-opacity-10 text-white hover:bg-opacity-20",
        outline:
          "bg-transparent text-zinc-300 hover:bg-brand-pink/5 border-2 border-zinc-400/60 hover:border-zinc-400/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "underline-offset-4 hover:underline text-primary",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      },
      size: {
        xl: "md:h-11 py-2 text-lg font-semibold h-10",
        lg: "md:h-10 py-1.5 text-lg font-semibold h-9",
        default: "md:h-9 py-1.5 text-base h-8 font-medium",
        sm: "md:h-8 py-1 text-sm h-7 font-medium",
        xs: "md:h-7 py-1 text-xs h-6 font-medium",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  }
);
Button.displayName = "Button";

export default Button;
