import { useSession } from "next-auth/react";
import { type PropsWithChildren } from "react";
import Context from ".";

const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const session = useSession();

  const value = {
    user: {
      user: session.data?.user || null,
      isAuthenticated: session.status === "authenticated",
      isAuthReady: session.status !== "loading",
      update: session.update,
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ContextProvider;
