/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { type Session } from "next-auth";
import { getUTMQueries } from "../url";
import { type PropertyType } from "app/global";

class MixpanelClass {
  track = (name: string, props: { [key: string]: any }) => {
    if (window.mixpanel) {
      window.mixpanel.track(name, props);
    }
  };

  pageView = (url: string, title: string) => {
    setTimeout(() => {
      const utm = getUTMQueries();
      this.track("Page View", { url, title, ...utm });
      window.mixpanel.register(utm);
    }, 500);
  };

  identify = (user: Session["user"]) => {
    if (window.mixpanel) {
      window.mixpanel.identify(user.id);
      window.mixpanel.alias(user.id);
      window.mixpanel.people.set({
        $email: user.email,
        email: user.email,
        "User ID": user.id,
      });
    }
  };

  signUp = (properties: PropertyType) => {
    this.track("Sign Up", properties);
  };

  designRequested = (properties: PropertyType) => {
    window.mixpanel.track("Design Requested", properties);
  };

  designGenerated = (properties: PropertyType) => {
    window.mixpanel.track("Design Generated", properties);
  };

  shopping = (properties: PropertyType) => {
    window.mixpanel.track("Google Lens Shopping", properties);
  };

  imageDownloaded = (properties: PropertyType) => {
    window.mixpanel.track("Image Downloaded", properties);
  };

  imageFeedback = (properties: PropertyType) => {
    window.mixpanel.track("Image Feedback", properties);
  };

  imageZoom = (properties: PropertyType) => {
    window.mixpanel.track("Click on Zoom Modal", properties);
  };

  share = (properties: PropertyType) => {
    window.mixpanel.track("Share", properties);
  };

  pricingModalPopup = (properties: PropertyType) => {
    window.mixpanel.track("Pricing Modal Popup", properties);
  };

  comingSoonPopup = (properties: PropertyType) => {
    window.mixpanel.track("Coming Soon Popup", properties);
  };

  click = (item: string, properties?: PropertyType) => {
    window.mixpanel.track("Click", { item, ...properties });
  };

  joinWaitList = (properties?: PropertyType) => {
    window.mixpanel.track("Wait List", properties);
  };

  moodboard = (event_name: string, properties?: PropertyType) => {
    window.mixpanel.track(event_name, properties);
  };

  findAProFormStart = (properties?: PropertyType) => {
    window.mixpanel.track("Find A Pro Form Start", properties);
  };

  findAProFormEnd = (properties?: PropertyType) => {
    window.mixpanel.track("Find A Pro Form End", properties);
  };

  discoverFilter = (name: string, properties?: PropertyType) => {
    window.mixpanel.track(name, properties);
  };

  homepageBarLink = (properties?: PropertyType) => {
    window.mixpanel.track("Homepage Bar Click", properties);
  };

  homepageNavBarLink = (properties?: PropertyType) => {
    window.mixpanel.track("Homepage Navbar Click", properties);
  };

  proLandingNavBarLink = (properties?: PropertyType) => {
    window.mixpanel.track("ProLanding Navbar Click", properties);
  };

  dashboardNavBarLink = (properties?: PropertyType) => {
    window.mixpanel.track("Dashboard Navbar Click", properties);
  };

  dashboardEditProject = (properties?: PropertyType) => {
    window.mixpanel.track("Dashboard Edit Project", properties);
  };

  homepageCtaLink = (properties?: PropertyType) => {
    window.mixpanel.track("Homepage Click", properties);
  };
}

const Mixpanel = new MixpanelClass();

export default Mixpanel;
