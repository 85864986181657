import { type User } from "@prisma/client";
import Analytics from "app/utils/analytics-client";
import { useEffect } from "react";

function useAppInit(user: User | null) {
  useEffect(() => {
    if (user) {
      Analytics.identify(user);
    }
  }, [user]);
}

export default useAppInit;
