import { STATES, COUNTRIES } from "./address";

const constants = {
  CloudFront: "https://d2a3t0vlgucgyo.cloudfront.net",
  isMobile: (window: { innerWidth?: number; width?: number }) =>
    Number(window.innerWidth || window?.width) < 768,
  STATES,
  COUNTRIES,
  SEO: {
    title: "Roomable: Design your dream home renovation on Roomable for free!",
    description:
      "Plan your home renovation, design it with AI, and connect with trusted professionals to make it real! Get started for free today.",
  },
};

export default constants;
