import { type User } from "@prisma/client";
import { type Session } from "next-auth";
import { createContext } from "react";

// Use for only Read Only data that rarely changes

type ContextUserType = {
  user: User | null;
  isAuthenticated: boolean;
  isAuthReady: boolean;
  update: (data?: any) => Promise<Session | null>;
};

type ContextType = {
  user: ContextUserType;
};

const ContextDefault: ContextType = {
  user: {
    user: null,
    isAuthenticated: false,
    isAuthReady: false,
    update: () => Promise.resolve(null),
  },
};

const Context = createContext<ContextType>(ContextDefault);

export default Context;
