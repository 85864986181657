/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { api } from "app/utils/api";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppProps } from "next/app";
import { IBM_Plex_Sans, IBM_Plex_Serif } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect, type ReactElement, type ReactNode } from "react";
import CookieConsent from "react-cookie-consent";

import Layout from "app/components/layout/Layout";
import constants from "app/constants/client";
import ContextProvider from "app/context/ContextProvider";
import useContextUser from "app/context/useContextUser";
import { env } from "app/env.mjs";
import useAppInit from "app/hooks/useAppInit";
import "app/styles/globals.css";
import Analytics from "app/utils/analytics-client";
import { type NextPage } from "next";

const IBM_Plex_SansFont = IBM_Plex_Sans({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600"],
  variable: "--font-ibm",
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IBM_Plex_SerifFont = IBM_Plex_Serif({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600"],
  style: ["italic", "normal"],
  variable: "--font-ibm-serif",
});

const InitUser = () => {
  const { user } = useContextUser();
  useAppInit(user);
  return null;
};

export type NextPageWithLayout<P = NonNullable<unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<{ session: Session | null }> & {
  Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) =>
      Analytics.pageView(url, window.document.title.replace("Roomable.ai -", ""));

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return (
    <>
      <Head>
        <title>{constants.SEO.title}</title>
        <meta name="description" content={constants.SEO.title} />
        <link rel="icon" href="/favicon.ico" />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="fragment" content="!" />
        <meta name="robots" content="noodp, noydir, index, follow, archive" />
        <meta name="robots" content="max-image-preview:standard" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#0f172a" />
        <meta name="author" content="Roomable" />
        {/* <meta property="fb:app_id" content="todo" /> */}
        <link rel="canonical" href="https://roomable.ai" />

        <meta name="title" content={constants.SEO.title} key="title" />
        <meta name="description" content={constants.SEO.description} key="description" />
        <meta
          name="keywords"
          content="room, ai, interior design, design, artist, generate, roomgpt, chatgpt, bedroom, home decor"
        />

        <meta property="og:url" content="https://roomable.ai" />
        <meta property="og:title" content={constants.SEO.title} key="og:title" />
        <meta
          property="og:image"
          content="https://d2a3t0vlgucgyo.cloudfront.net/assets/open-graph.png"
          key="og:image"
        />
        <meta property="og:image:alt" content={constants.SEO.title} />
        <meta property="og:description" content={constants.SEO.description} />

        <meta property="og:site_name" content="Roomable" />
        <meta property="og:type" content="website" />
      </Head>
      <style jsx global>{`
        html {
          font-family: ${IBM_Plex_SansFont.style.fontFamily};
          --font-ibm-serif: ${IBM_Plex_SerifFont.style.fontFamily};
        }
      `}</style>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
      ></Script>
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${env.NEXT_PUBLIC_GA_MEASUREMENT_ID}", {
            page_path: window.location.pathname,
          });
        `,
        }}
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${env.NEXT_PUBLIC_GTM_ID}');`,
        }}
      />
      {typeof window != "undefined" && !window.mixpanel && (
        <Script
          id="mixpanel-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
            MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);

            mixpanel.init('${env.NEXT_PUBLIC_MIXPANEL_ID}')

            mixpanel.track("Page View", { url:  window.location.pathname });
  `,
          }}
        />
      )}
      {typeof window != "undefined" && (
        <Script
          strategy="afterInteractive"
          async
          type="text/javascript"
          src="//script.crazyegg.com/pages/scripts/0118/3729.js"
        />
      )}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${env.NEXT_PUBLIC_GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <SessionProvider session={session}>
        <ContextProvider>
          <InitUser />
          {Component.getLayout ? (
            Component.getLayout(<Component {...pageProps} />)
          ) : (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          )}
        </ContextProvider>
        <CookieConsent
          // debug
          location="bottom"
          buttonText="Got it"
          acceptOnScroll
          containerClasses="mx-auto !justify-center !items-center py-0 md:py-1 !bg-brand-dark border-t-2 border-zinc-100/20"
          contentClasses="container flex max-w-[260px] px-1 sm:max-w-[300px] md:max-w-[400px] m-0"
          buttonClasses="!m-0 !rounded-sm !bg-brand-dark !text-white text-xs md:text-sm"
          buttonStyle={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
        >
          <div className="text-xs md:text-sm">
            This website uses cookies to enhance the user experience.
          </div>
        </CookieConsent>
      </SessionProvider>
    </>
  );
};

export default api.withTRPC(MyApp);
